import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Box,
  Divider,
  Paper,
  Fab,
  Fade,
  CssBaseline,
  useScrollTrigger
} from '@mui/material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  KeyboardArrowDown as ArrowDownIcon,
  ArrowUpward as ArrowUpwardIcon,
  School as SchoolIcon
} from '@mui/icons-material';
import bg1 from './files/Bible.webp';
import logo from './files/logo.webp';

const sections = [
  { id: 'hero', label: 'Welcome' },
  { id: 'vision', label: 'Vision' },
  { id: 'message', label: 'Message' },
  { id: 'find', label: 'Find Us'}
];

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#ccc',
        fontSize: '0.8rem',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2">
          Copyright © 2024-{year} AIOLA. All rights reserved.
          <Link
            to="/privacy-policy"
            style={{
              color: '#fff',
              marginLeft: '8px',
              textDecoration: 'none',
            }}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

const SectionIndicators = ({ activeSection }) => (
  <Box
    sx={{
      position: 'fixed',
      right: {
        xs: 28,
        md: 36
      },
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    }}
  >
    {sections.map((section, index) => (
      <Box
        key={section.id}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          cursor: 'pointer',
        }}
        onClick={() => {
          document.getElementById(section.id)?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <Typography
          sx={{
            opacity: activeSection === section.id ? {
              xs: 0,
              lg: 1
            }:
            {
              xs: 0,
              lg: 0.4
            },
            transform: 'translateX(-10px)',
            transition: 'all 0.2s ease',
            position: 'absolute',
            right: '100%',
            marginRight: '16px',
            whiteSpace: 'nowrap',
            color: 'white',
            textShadow: '0 0 5px rgba(0,0,0,0.5)',
            fontWeight: 500,
            fontSize: '1.1rem',
            '&:hover': {
              opacity: {
                xs: 0,
                lg: 1
              },
              transform: 'translateX(0)',
            }
          }}
        >
          {section.label}
        </Typography>

        <Box
          sx={{
            opacity: {
              xs: 0,
              md: 1
            },
            width: activeSection === section.id ? 20 : 14,
            height: activeSection === section.id ? 20 : 14,
            borderRadius: '50%',
            backgroundColor: 'white',
            transition: 'all 0.3s ease',
            boxShadow: '0 0 8px rgba(0,0,0,0.3)',
            '&:hover': {
              width: 20,
              height: 20,
              '& + Typography': {
                opacity: 1,
                transform: 'translateX(0)',
              }
            },
            '&::before': activeSection === section.id ? {
              content: '""',
              position: 'absolute',
              width: 36,
              height: 36,
              borderRadius: '50%',
              border: '2px solid rgba(255, 255, 255, 0.6)',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              transition: 'all 0.3s ease',
            } : {},
          }}
        />
      </Box>
    ))}
  </Box>
);

const ParallaxElement = ({ children, direction = 'left', delay = 0 }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const element = elementRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2,
        rootMargin: '0px 0px -10% 0px'
      }
    );

    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={elementRef}
      sx={{
        transform: isVisible
          ? 'translateX(0)'
          : `translateX(${direction === 'left' ? '-50px' : '50px'})`,
        opacity: isVisible ? 1 : 0,
        transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDelay: `${delay}ms`,
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};



const Section = ({ id, children, backgroundImage, backgroundColor = 'white', color = 'black', isLastSection }) => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={sectionRef}
      id={id}
      sx={{
        height: '100vh',
        width: '100%',
        position: 'relative',
        scrollSnapAlign: 'start',
        overflow: 'hidden',
        backgroundColor,
        color,
        display: 'flex',
        flexDirection: 'column',
        ...(backgroundImage && {
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 1
          }
        })
      }}
    >
      {backgroundImage && (
        <Box
          component="img"
          src={backgroundImage}
          alt="Section background"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'bottom'
          }}
        />
      )}
      <Fade in={isVisible} timeout={1000}>
        <Container
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            zIndex: 2,
            textAlign: 'center',
          }}
        >
          {children}
        </Container>
      </Fade>
      {isLastSection && <Footer />}
    </Box>
  );
};


const ScrollIndicator = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: 40,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 2,
      animation: 'bounce 2s infinite',
      '@keyframes bounce': {
        '0%, 20%, 50%, 80%, 100%': {
          transform: 'translateY(0) translateX(-50%)',
        },
        '40%': {
          transform: 'translateY(-20px) translateX(-50%)',
        },
        '60%': {
          transform: 'translateY(-10px) translateX(-50%)',
        }
      }
    }}
  >
    <ArrowDownIcon sx={{ fontSize: 120, color: 'white' }} />
  </Box>
);

const Home = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '-20% 0px',
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach(section => {
      const element = document.getElementById(section.id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);


  return (
    <Box
      sx={{
        height: '100vh',
        overflowY: 'scroll',
        scrollSnapType: 'y mandatory',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none',
      }}
    >
      <SectionIndicators activeSection={activeSection} />

      <Section id="hero" backgroundImage={bg1}>
        <ParallaxElement delay={150}>
          <Typography
            variant="h2"
            sx={{
              mb: 2,
              fontSize: {
                xs: '2rem',
                sm: '3rem',
                md: '4rem'
              },
              fontWeight: 'bold',
              color:'white',
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
            }}
          >
            Welcome to
          </Typography>
        </ParallaxElement>

        <ParallaxElement delay={300}>
          <Typography
            variant="h2"
            sx={{
              mb: 2,
              fontSize: {
                xs: '2.5rem',
                sm: '3.5rem',
                md: '4.5rem'
              },
              fontWeight: 'bold',
              color:'white',
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
            }}
          >
            Agape Institute of Los Angeles
          </Typography>
        </ParallaxElement>



        <ParallaxElement delay={450}>
          <Typography
            variant="h3"
            sx={{
              mb: 4,
              fontSize: {
                xs: '1.2rem',
                sm: '1.5rem',
                md: '2rem'
              },
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
              color: 'white'
            }}
          >
            AIOLA empowers people through transformational biblical education to serve the church and impact the world for Christ.
          </Typography>
        </ParallaxElement>
        <ScrollIndicator />
      </Section>

      <Section id="vision" backgroundColor="#B22222">

      <ParallaxElement
        direction={'left'}
        delay={150}
      >
      <Divider variant="middle" sx={{marginBottom:2, borderColor:'rgba(255, 255, 255, 0.4);'}}/>
      <Typography sx={{
        fontSize: {
          xs: '2rem',
          sm: '3rem',
          md: '4rem'
        },
        color: 'white',
        fontWeight: 'bold',
        textShadow: '2px 2px 4px rgba(0,0,0,0.3)'

      }} paragraph>
        Do not die without Jesus!
      </Typography>
      <Divider variant="middle" sx={{marginBottom:{xs:2, md:6}, borderColor:'rgba(255, 255, 255, 0.4);'}}/>
      </ParallaxElement>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 'md' }}>

        <ParallaxElement
          direction={'right'}
          delay={300}
        >
          <Typography sx={{
            mb: 2,
            fontSize: {
              xs: '1rem',
              sm: '1.5rem',
              md: '2rem'
            },
            color:'white',
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)'

          }} paragraph>
            Our vision is to produce the maximum number of <Typography sx={{display:'inline', mb: 2,
            fontSize: {
              xs: '1.5rem',
              sm: '2rem',
              md: '2.5rem'
            },
            color:'white'}}>fishers of men</Typography>. We shall participate in awakening all Christians in preaching the <Typography sx={{display:'inline',mb: 2,
            fontSize: {
              xs: '1.5rem',
              sm: '2rem',
              md: '2.5rem'
            },
            color:'white'}}>true Gospel</Typography> at this harvest time.
          </Typography>
        </ParallaxElement>







        </Box>
      </Section>

      <Section id="message" backgroundColor="#003060">


        <ParallaxElement
          direction={'right'}
          delay={150}
        >
        <Divider variant="middle" sx={{marginBottom:2, borderColor:'rgba(255, 255, 255, 0.4);'}}/>
        <Typography sx={{
          fontSize: {
            xs: '2rem',
            sm: '3rem',
            md: '4rem'
          },
          color: 'white',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0,0,0,0.3)'

        }} paragraph>
          Message from the President
        </Typography>
        <Divider variant="middle" sx={{marginBottom:{xs:2, md:6}, borderColor:'rgba(255, 255, 255, 0.4);'}}/>
        </ParallaxElement>


        <ParallaxElement
          direction={'left'}
          delay={300}
        >
          <Typography sx={{
            mb: 2,
            fontSize: {
              xs: '0.75rem',
              sm: '1.15rem',
              md: '2rem'
            },
            color: 'white',
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
          }} paragraph>
            I am pleased to welcome incoming students into AIOLA. There are many schools and they appear to be the same, but they are not. I cannot afford to establish another armchair argument seminary. I neither criticize nor underestimate them. Simply, they are not quite ready. First of all, all Christian workers must be born again to serve the body of Christ. Secondly, while many schools produce thousands of teachers to teach about Christ, we shall produce fathers who give birth through the Gospel. That makes a great difference. Above all else, each and every graduate of AIOLA shall be birth-giving fathers who shall fuel rapid progress in world mission activities.
          </Typography>
        </ParallaxElement>
      </Section>

      <Section id="find" backgroundColor="#999" color="white" isLastSection>


        <ParallaxElement direction="right" delay={100}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.0205283369255!2d-118.27879342286431!3d34.06898801684349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c772b4fdac5b%3A0x5d135e65266f20c2!2s2550%20Beverly%20Blvd%2C%20Los%20Angeles%2C%20CA%2090057!5e0!3m2!1sen!2sus!4v1728369398280!5m2!1sen!2sus" width="100%" height="500" style={{border:0, margin:'auto', display: 'block'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </ParallaxElement>

      </Section>

      <Fab onClick={() => {document.getElementById('hero')?.scrollIntoView({ behavior: 'smooth' })}} color="transparent" aria-label="top" style={{position: 'fixed',bottom: 32,right: 32,}}>
          <ArrowUpwardIcon />
        </Fab>
    </Box>
  );
};

const Admissions = () => (
  <Container sx={{ pt: 10, pb: 6, }} >
  <Paper sx={{padding:6}} elevation={1}>
    <Typography variant="h4" sx={{
      fontSize: {
        xs: '2rem',
        sm: '3rem',
        md: '4rem'
      },
      fontWeight:'bold'
    }}>Admission Standards</Typography>
    <Divider sx={{marginBottom:4}}/>
    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1rem',
        sm: '1.25rem',
        md: '1.5rem'
      },
    }}>
    <p>Applicants to the programs of AIOLA are evaluated on the basis of their faith, academic preparation and potential for ministry.</p>
    <p>1) Applicants to the B.A. must have a high school diploma or its equivalent.</p>
    <p>2) Applicants to the M.A. or M.Div. must hold the B.A. degree or its equivalent.</p>
    <p>3) Applicants to the D.Min. must hold the M.Div. degree and can demonstrate that they have 3 years of ministry experience.</p>
    <p>4) Applicants whose prior academic study is incomplete may be required to complete additional coursework as a condition of admission.</p>
    <p>For Master’s or Doctoral program applicants, their prior degrees must be completed from a college or university accredited by an accreditation agency recognized by the US Department of Education, from an institution approved by the California Bureau for Private Postsecondary Education, or from a national or international institution that meets these standards.</p>
    </Typography>
    </Paper>
    <Footer />
  </Container>
);

const Academics = () => (
  <Container sx={{ pt: 10, pb: 6, }} >
  <Paper sx={{padding:6}} elevation={1}>
    <Typography variant="h4" sx={{
      fontSize: {
        xs: '2rem',
        sm: '3rem',
        md: '4rem'
      },
      fontWeight:'bold'
    }}>Academics</Typography>
    <Divider sx={{marginBottom:4}}/>

    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1.5rem',
        sm: '2rem',
        md: '2.5rem'
      },
    }}>Associate in Ministry</Typography>
    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1rem',
        sm: '1.25rem',
        md: '1.5rem'
      },
      marginBottom: 6
    }}>
    <p>The A.A. in Ministry degree is a 66 semester unit program.</p>
    </Typography>

    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1.5rem',
        sm: '2rem',
        md: '2.5rem'
      },
    }}>Bachelor in Ministry</Typography>
    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1rem',
        sm: '1.25rem',
        md: '1.5rem'
      },
      marginBottom: 6
    }}>
    <p>The B.A. in Ministry degree is a 130 semester unit program. The minimum time necessary to complete the degree is four years and the maximum time is six years.</p>
    </Typography>

    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1.5rem',
        sm: '2rem',
        md: '2.5rem'
      },
    }}>Master in Ministry</Typography>
    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1rem',
        sm: '1.25rem',
        md: '1.5rem'
      },
      marginBottom: 6
    }}>
    <p>The M.A. in Ministry degree is a 64 semester unit program.</p>
    </Typography>


    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1.5rem',
        sm: '2rem',
        md: '2.5rem'
      },
    }}>Master of Divinity</Typography>
    <Typography variant="h4" sx={{
      fontSize: {
        xs: '1rem',
        sm: '1.25rem',
        md: '1.5rem'
      },
      marginBottom: 6
    }}>
    <p>The Master of Divinity degree is a 96 semester unit program. The M. Div. is a program of theological study designed primarily, although not exclusively, to prepare the candidate for ordination to Christian Ministry.</p>
    </Typography>

    </Paper>
    <Footer />
  </Container>
);

const PrivacyPolicy = () => (
  <Container sx={{ pt: 10, pb: 6, }} >
    <Paper sx={{padding:6}} elevation={1}>
      <Typography variant="h4" sx={{
        fontSize: {
          xs: '2rem',
          sm: '3rem',
          md: '4rem'
        },
        fontWeight:'bold'
      }}>Privacy Policy</Typography>
      <Divider sx={{marginBottom:4}}/>
      <Typography variant="h4" sx={{
        fontSize: {
          xs: '1rem',
          sm: '1.25rem',
          md: '1.5rem'
        },
      }}>
      <p>Agape Institute of Los Angeles (AIOLA) is dedicated to protecting the privacy of individuals who interact with our website. This privacy policy outlines how we collect, use, and safeguard personal information in compliance with relevant data protection laws. Specific web pages under the AIOLA2021.com domain may have additional privacy notices pertaining to information collected on those pages.</p>
      <p>When you visit our website, AIOLA gathers non-personal information such as IP addresses, browser types, and site usage patterns. This data is anonymous and not linked to any personal details. We may use session "cookies" - small text files placed on your device - to collect this information. These cookies are temporary and do not provide us with personal data or access to your device. You can adjust your browser settings to refuse cookies, but this may limit your experience on our site.</p>
      <p>AIOLA does not intentionally collect personal information from minors under 13 without parental consent. Parental consent may be implied through actions such as using a parent's login, a parent-provided credit card, or including parent information on registration forms.</p>
      <p>AIOLA utilizes non-personal information to enhance your browsing experience and provide more tailored responses. We also compile anonymous statistical reports to improve our website administration, address technical issues, and enhance our online presence.</p>
      <p>To protect against loss, misuse, or alteration of collected information, all data is stored in a secure database accessible only to authorized AIOLA personnel. While we implement robust security measures, no system is completely impenetrable. AIOLA cannot guarantee absolute security of our database or the information transmitted over the Internet.</p>
      <p>Our website may contain links to external sites outside the AIOLA2021.com domain, including affiliated sites that may or may not be operated by AIOLA. These external sites are not governed by this privacy policy, and we encourage users to review the privacy practices of those sites.</p>
      </Typography>
    </Paper>
    <Footer />
  </Container>
);

const App = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Admissions', icon: <InfoIcon />, path: '/admissions' },
    { text: 'Academics', icon: <SchoolIcon />, path: '/academics' }
  ];

  const drawer = (
    <List>
      {menuItems.map((item) => (
        <ListItem button key={item.text} component={Link} to={item.path}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <CssBaseline />

        {/* App Bar */}
        <AppBar
          position="fixed"
          sx={{
            background: trigger ? 'rgba(169, 169, 169, 0.8)' : 'rgba(255, 255, 255, 0)',
            backdropFilter: trigger ? 'blur(10px)' : 'none',
            boxShadow: trigger ? 1 : 'none',
            transition: 'all 0.3s ease',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: 'none' },
                color: trigger ? 'inherit' : 'white'
              }}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <Paper sx={{
                maxWidth: {
                  xs: 100,
                  sm: 150,
                  md: 250
                },
                '&:hover': {
                  opacity: 0.8,
                  transition: 'opacity 0.3s ease'
                }
              }} >
                <img style={{ width:'100%', padding:'8px' }} src={logo} alt="AIOLA Logo"/>
              </Paper>
            </Link>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: trigger ? 'inherit' : 'white'
              }}
            >

            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  component={Link}
                  to={item.path}
                  startIcon={item.icon}
                  sx={{
                    color: trigger ? 'inherit' : 'white',
                    '&:hover': {
                      backgroundColor: trigger ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  {item.text}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>

        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          {drawer}
        </Drawer>

        {/* Main Content */}
        <Box component="main" sx={{ flexGrow: 1, backgroundColor:'#999' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admissions" element={<Admissions />} />
            <Route path="/academics" element={<Academics />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
